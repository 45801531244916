import { AnimateIn } from '@/web/components/AnimateIn'
import type { NavbarActiveSectionId } from '@/web/constants/navigation'
import { cn } from '@/web/libs/utils'
import type { ComponentProps } from 'react'

export const LandingSection = ({
  id,
  children,
  isFirst = id === 'hero',
  className,
  ...props
}: ComponentProps<typeof AnimateIn> & {
  id: NavbarActiveSectionId
  isFirst?: boolean
}) => (
  <AnimateIn
    {...props}
    className={cn(
      'w-full px-4 py-8 lg:px-8 xl:py-24 xl:px-24 2xl:px-32 2xl:py-32',
      isFirst && 'lg:pt-32 xl:pt-40 2xl:pt-48',
      className
    )}
    id={id}>
    {children}
  </AnimateIn>
)
